import { ApiProperty } from '@nestjs/swagger'
import { Type } from 'class-transformer'
import { IsNotEmpty, IsNumber } from 'class-validator'
import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { SchoolYear } from '../../calendar/entities'
import { EvaluationSchemaAssignment } from '../../evaluation-schema'
import { File } from '../../files/entities'
import { Conversation } from '../../messages'
import { PublicTransportRequirement } from '../../public-transport'
import { TripRequirement } from '../../routing'
import { School } from '../../schools/entities'
import { Student } from '../../students/entities'
import { SharedMemoryChanges, System } from '../../system/entities'
import { BankAccount } from '../../users'
import { AddressPrefixed, CoordinatesPrefixed, ValidTimespan } from '../../utils'
import type { VariableFieldsValues } from '../../variable-fields'
import { SchoolAffiliationStatus } from '../types/SchoolAffiliationStatus'
import { LessonTimes } from './LessonTimes.entity'
import { TransportRequestAttribute } from './TransportRequestAttribute.entity'
import { TransportRequestDistances } from './TransportRequestDistances.entity'
import { TransportRequestEvaluationLogs } from './TransportRequestEvaluationLogs.entity'
import { TransportRequestGuardians } from './TransportRequestGuardians.entity'
import { TransportRequestNearestSchools } from './TransportRequestNearestSchools'
import { TransportRequestRefunds } from './TransportRequestRefunds.entity'
import { TransportRequestSiblings } from './TransportRequestSiblings.entity'

@Index('id', ['id'], { unique: true })
@Entity('tbl_antraege')
export class TransportRequest {
    @ApiProperty({ type: Number })
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number

    @ApiProperty({ type: () => Student })
    @IsNumber({}, { always: true })
    @ManyToOne((type) => Student, (student) => student.requests, {
        nullable: false,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    @JoinColumn({ name: 'schueler_id' })
    student: Student

    @Column({ name: 'schueler_id', type: 'int', width: 10, unsigned: true, nullable: false })
    studentId: number

    @ApiProperty({ type: () => SchoolYear })
    @IsNotEmpty()
    // @IsSchoolYear({ always: true})
    @ManyToOne((type) => SchoolYear, (schoolYear) => schoolYear.requests, {
        nullable: false,
        onUpdate: 'CASCADE',
        onDelete: 'RESTRICT'
    })
    @JoinColumn({ name: 'sj_id' })
    schoolYear: SchoolYear

    @Column({ name: 'sj_id', type: 'int', unsigned: true, width: 10, nullable: false })
    schoolYearId: number

    @ApiProperty({ type: () => School })
    @ManyToOne((type) => School, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'RESTRICT'
    })
    @JoinColumn({ name: 'schule_id_besucht' })
    visitedSchool: School | null

    @Column({ name: 'schule_id_besucht', type: 'int', width: 10, unsigned: true, nullable: true })
    visitedSchoolId: number

    @ApiProperty({ type: () => School })
    @ManyToOne((type) => School, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'SET NULL'
    })
    @JoinColumn({ name: 'schule_id_naechstgelegen' })
    closestSchool: School | null

    @Column({ name: 'schule_id_naechstgelegen', type: 'int', width: 10, unsigned: true, nullable: true })
    closestSchoolId: number

    // Probably not a relation
    // Improve: Make relation to SchoolClass (?)
    @ApiProperty({ type: String, nullable: true })
    @Column('text', { name: 'schule_klasse', nullable: true })
    class: string | number | null

    @ApiProperty({ type: () => System })
    @ManyToOne((type) => System, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'RESTRICT'
    })
    @JoinColumn({ name: 'schule_form' })
    form: System | null

    @Column({ name: 'schule_form', type: 'int', width: 10, unsigned: true, nullable: true })
    formId: number | null

    @ApiProperty({ type: () => System })
    @ManyToOne((type) => System, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'RESTRICT'
    })
    @JoinColumn({ name: 'schule_profil' })
    profile: System | null

    @Column({ name: 'schule_profil', type: 'int', width: 10, unsigned: true, nullable: true })
    profileId: number | null

    @ApiProperty({ type: ValidTimespan })
    @Column((type) => ValidTimespan)
    @Type(() => ValidTimespan)
    valid: ValidTimespan

    @ApiProperty({ type: Boolean })
    @IsNotEmpty()
    @Column('tinyint', {
        name: 'hat_oepnv',
        nullable: false,
        width: 1,
        default: false
    })
    hasPublicTransport: boolean

    @ApiProperty({ type: Boolean })
    @Column('tinyint', { name: 'hat_fsv', nullable: false, default: false })
    hasIndividualStudentTransport: boolean

    @ApiProperty({ type: Boolean })
    @Column('tinyint', {
        name: 'hat_erstattung',
        default: false
    })
    hasRefund: boolean

    @ApiProperty({ type: Boolean })
    @Column((type) => AddressPrefixed)
    address: AddressPrefixed

    @ApiProperty({ type: CoordinatesPrefixed })
    @Column((type) => CoordinatesPrefixed)
    coordinates: CoordinatesPrefixed

    @ApiProperty({ type: String, nullable: true })
    @Column('json', { name: 'var_fields_antrag', nullable: true })
    variableFieldsRequest: [VariableFieldsValues] | null

    @ApiProperty({ type: String, nullable: true })
    @Column('json', { name: 'var_fields_entscheid', nullable: true })
    variableFieldsDecision: [VariableFieldsValues] | null

    @ApiProperty({ type: Number, nullable: true })
    @Column('int', {
        name: 'km_mindestentfernung_ist',
        nullable: true,
        default: () => "'0'"
    })
    minimumDistance: number | null

    @OneToOne((type) => TransportRequestDistances, (requestDistance) => requestDistance.request, { cascade: true })
    distances: TransportRequestDistances

    @OneToMany((type) => TripRequirement, (tripRequirement) => tripRequirement.request)
    requirements: TripRequirement[]

    @ApiProperty()
    @OneToMany((type) => File, (file) => file.request, { cascade: true })
    files: File[]

    @Column('text', { name: 'status' })
    status: string

    @Column('tinyint', { name: 'ist_selbstzahler', nullable: false, default: false })
    selfPay: boolean

    @Column({ type: 'enum', enum: SchoolAffiliationStatus, default: SchoolAffiliationStatus.APPROVED, nullable: false })
    schoolAffiliationStatus: SchoolAffiliationStatus

    @Column('varchar', { length: 1000, nullable: true })
    schoolAffiliationNote: string | null

    @OneToMany((type) => PublicTransportRequirement, (requirement) => requirement.transportRequest)
    publicTransportRequirements: PublicTransportRequirement[]

    @OneToMany((type) => Conversation, (conversation) => conversation.transportRequest)
    conversations: Conversation[]

    @ManyToOne(
        (type) => EvaluationSchemaAssignment,
        (evaluationSchemaAssignment) => evaluationSchemaAssignment.transportRequests,
        { nullable: true }
    )
    @JoinColumn({ name: 'usedEvaluationSchemaAssignmentId' })
    usedEvaluationSchemaAssignment: EvaluationSchemaAssignment

    @Column({
        name: 'usedEvaluationSchemaAssignmentId',
        type: 'int',
        unsigned: false,
        width: 11,
        nullable: true
    })
    usedEvaluationSchemaAssignmentId: number

    // Should not be null for new transport requests but older requests do not have this information
    @ManyToOne((type) => BankAccount, (bankAccount) => bankAccount.transportRequests, {
        nullable: true,
        cascade: true,
        onDelete: 'RESTRICT',
        onUpdate: 'RESTRICT'
    })
    @JoinColumn({ name: 'bankAccountId' })
    bankAccount: BankAccount

    @Column({ name: 'bankAccountId', type: 'int', unsigned: true, nullable: true })
    bankAccountId: number

    @Column({ name: 'orderDate', type: 'date', nullable: true })
    orderDate: Date | null

    @Column({ name: 'notes', type: 'text', nullable: true })
    notes: string | null

    @Column({ name: 'var_fields_erstattung', type: 'json', nullable: true })
    variableFieldsRefund: [VariableFieldsValues] | null

    @OneToOne((type) => TransportRequestEvaluationLogs, (evaluationLogs) => evaluationLogs.request, { cascade: true })
    evaluationLogs: TransportRequestEvaluationLogs

    @OneToMany((type) => TransportRequestAttribute, (attribute) => attribute.transportRequest)
    attributes: TransportRequestAttribute[]

    @OneToMany((type) => TransportRequestSiblings, (sibling) => sibling.transportRequest)
    siblings: TransportRequestSiblings[]

    @OneToMany((type) => TransportRequestNearestSchools, (closestSchools) => closestSchools.request)
    closestSchools: TransportRequestNearestSchools[]

    @OneToMany((type) => TransportRequestGuardians, (guardians) => guardians.transportRequest)
    guardians: TransportRequestGuardians[]

    @OneToOne((type) => LessonTimes, (lessonTimes) => lessonTimes.request)
    @Type(() => LessonTimes)
    lessonTimes: LessonTimes

    @OneToMany((type) => TransportRequestRefunds, (refunds) => refunds.transportRequest)
    refunds: TransportRequestRefunds[]

    @OneToMany((type) => SharedMemoryChanges, (sharedMemoryChanges) => sharedMemoryChanges.transportRequest)
    sharedMemoryChanges: SharedMemoryChanges[]

    @ManyToOne((type) => TransportRequest, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'SET NULL'
    })
    @JoinColumn({ name: 'parent_tbl_antraege_id' })
    parentTransportRequest: TransportRequest | null

    constructor(init?: Partial<TransportRequest>) {
        Object.assign(this, init)
    }
}
