import { TransportRequest } from '../../transport-request'
import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm'
import type { SharedMemoryChangesContent } from '../../utils/shared-memory-types'
import { SharedMemoryChangesType } from '../../utils/shared-memory-types'

@Entity('shared_memory_changes')
export class SharedMemoryChanges {
    @PrimaryGeneratedColumn({ type: 'int', unsigned: true })
    id: number

    @Column({
        type: 'enum',
        enum: SharedMemoryChangesType
    })
    type: SharedMemoryChangesType

    @Column({ type: 'json' })
    content: SharedMemoryChangesContent

    @CreateDateColumn({ name: 'created_at', type: 'datetime' })
    createdAt: Date

    @Column({ name: 'notified_at', type: 'datetime', nullable: true })
    notifiedAt: Date | null

    @ManyToOne(() => TransportRequest, (transportRequest) => transportRequest.sharedMemoryChanges, {
        onDelete: 'CASCADE',
        onUpdate: 'CASCADE',
        nullable: true
    })
    @JoinColumn({ name: 'request_id' })
    transportRequest: TransportRequest | null
}
