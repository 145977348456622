import {
    AddressByLetterVerification,
    BankAccount,
    BaseUserID,
    Conversation,
    EmailVerification,
    Guardian,
    InfoBoxesDto,
    SingleInfoBox,
    System,
    SystemID,
    TransportRequest,
    TransportRequestPageConfiguration,
    TransportRequestState,
    TransportRequestTypeCards,
    TransportRequestDecisionVisibility,
    SchoolYear,
    AddressVerificationMethod,
    Student,
    GeneralFrontendConfigurationDTO,
    File,
    CheckboxesConfigurationDto,
    CategoryFrequentlyAskedQuestions,
    BundIDStatus,
    JWTPayload,
    Language,
    TransportRequestStatus,
    BaseUser,
    NotificationSettings
} from '@stadtlandnetz/core'
import type { SelectOption } from '@stadtlandnetz/forms'
import { Writable, writable } from 'svelte/store'

export const frontendConfig: Writable<GeneralFrontendConfigurationDTO> = writable(new GeneralFrontendConfigurationDTO())
export const addressVerificationMethod: Writable<AddressVerificationMethod> = writable(null)
export const logo: Writable<string> = writable(null)
export const customerKey: Writable<string> = writable(null)
export const requestPageConfiguration: Writable<TransportRequestPageConfiguration> = writable(null)
export const requestTypeCards: Writable<TransportRequestTypeCards> = writable(null)
export const requestDecisionVisibility: Writable<TransportRequestDecisionVisibility> = writable(null)
export const schoolTypes = writable<SelectOption<SystemID>[]>([])
export const schoolYears: Writable<SchoolYear[]> = writable([])
export const jwt: Writable<JWTPayload> = writable(null)
export const baseUserId: Writable<BaseUserID> = writable(null)
export const loggedInUser: Writable<BaseUser> = writable(null)
export const bundID: Writable<BundIDStatus> = writable(null)
export const verificationComplete: Writable<boolean> = writable(true)
export const mailStatus: Writable<EmailVerification> = writable(null)
export const addressStatuses: Writable<AddressByLetterVerification[]> = writable(null)
export const guardian: Writable<Guardian> = writable(null)
export const guardians: Writable<Guardian[]> = writable([])
export const isSelfApplicant: Writable<boolean> = writable(false)
export const students: Writable<Student[]> = writable([])
export const conversations: Writable<Conversation[]> = writable(null)
export const sentTransportRequests: Writable<TransportRequest[]> = writable([])
export const transportRequestStateMapping: Writable<Record<TransportRequestState, TransportRequestStatus[]>> =
    writable(null)
export const error: Writable<string> = writable(null)
export const warning: Writable<string> = writable(null)
export const success: Writable<string> = writable(null)
export const bankAccount: Writable<BankAccount> = writable(new BankAccount({}))
export const additionalAttributes: Writable<System[]> = writable(null)
export const infoBoxes: Writable<InfoBoxesDto> = writable(null)
export const bankAccountLegalText: Writable<SingleInfoBox> = writable(null)
export const guardianTypes: Writable<System[]> = writable(null)
export const guardianBirthdayOptional: Writable<boolean> = writable(false)
export const notificationSettingsConfiguration: Writable<NotificationSettings> = writable(null)
export const schoolAffiliationNoteRequired: Writable<boolean> = writable(false)
export const showMobileRequest: Writable<boolean> = writable(false)
export const footerData: Writable<System[]> = writable(null)
export const frequentlyAskedQuestions: Writable<CategoryFrequentlyAskedQuestions[]> = writable(null)
export const savedFiles: Writable<File[]> = writable([])
export const checkboxesConfiguration: Writable<CheckboxesConfigurationDto> = writable(null)
export const appDataReady: Writable<boolean> = writable(false)
export const modalOpen: Writable<boolean> = writable(false)
//@ts-ignore
export const userDataPrivacyDecision: Writable<'true' | 'false' | undefined> = writable(
    localStorage?.getItem('dataPrivacyDecision') ?? undefined
)
export const showGoBackToDraftBanner: Writable<{ draftId?: number; entityWasSaved?: boolean }> = writable({})
export const enabledLanguages: Writable<Language[]> = writable([Language.GERMAN, Language.ENGLISH])
