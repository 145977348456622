import { ApiHideProperty, ApiProperty } from '@nestjs/swagger'
import { Column, Entity, Index, JoinColumn, ManyToMany, OneToMany, OneToOne, PrimaryGeneratedColumn } from 'typeorm'
import { File } from '../../files'
import { Conversation, Message, UserFileInteraction, UserMessageInteraction } from '../../messages'
import { Guardian, Student } from '../../students'
import { TransportCompanyMember } from '../../companies'
import { Language } from '../../system'
import { TransportRequestDraft } from '../../transport-request'
import { BankAccount } from './BankAccount.entity'
import { OneTimePin } from './one-time-pin.entity'
import { AddressByLetterVerification, EmailVerification } from './verification'
import { BundIDStatus } from '../types'
import type { NotificationSettings } from '../types'

export type BaseUserID = number

@Entity()
export class BaseUser {
    @ApiProperty({ type: Number })
    @PrimaryGeneratedColumn()
    id: number

    // This can also be an email address, e.g. for parents
    @ApiProperty({ type: String })
    @Index({ unique: true })
    @Column({ type: 'varchar', length: 255, nullable: true })
    username: string

    @ApiHideProperty()
    @Column({ type: 'varchar', length: 255, nullable: true, select: false })
    refresh_token: string

    @ApiHideProperty()
    @Column('varchar', { name: 'password', nullable: false, length: 255, select: false })
    password: string

    @Column({ type: 'tinyint', nullable: true, width: 1 })
    active: boolean

    @ApiProperty({ type: Boolean })
    @Column({ type: 'tinyint', nullable: true, width: 1, default: 0 })
    isSelfApplicant: boolean

    @Column({ type: 'enum', enum: Language, nullable: false, default: Language.GERMAN })
    language: Language

    // BundID user identifier
    @Column({ type: 'varchar', length: 255, nullable: true, select: false })
    bPK2: string

    @Column({ type: 'enum', enum: BundIDStatus, nullable: true })
    bundIDStatus: BundIDStatus

    @Column({ type: 'json', nullable: true })
    notificationSettings: NotificationSettings

    @ApiHideProperty()
    passwordConfirm: string

    @OneToMany((type) => Student, (student) => student.user, { nullable: true })
    students: Student[]

    @OneToMany((type) => Guardian, (parent) => parent.user, { nullable: true })
    parents: Guardian[]

    // @ApiProperty({ type: () => Conversation, isArray: true })
    @ManyToMany((type) => Conversation, (conversation) => conversation.users)
    conversations: Conversation[]

    // @ApiProperty({ type: () => Message, isArray: true })
    @OneToMany((type) => Message, (message) => message.sentBy)
    messages: Message[]

    @OneToMany((type) => UserMessageInteraction, (interaction) => interaction.user)
    messageInteractions: UserMessageInteraction[]

    @OneToMany((type) => File, (file) => file.user)
    files: File[]

    @OneToMany((type) => UserFileInteraction, (interaction) => interaction.user)
    fileInteractions: UserFileInteraction[]

    @OneToMany((type) => EmailVerification, (verification) => verification.user)
    emailVerifications: EmailVerification

    @OneToMany((type) => AddressByLetterVerification, (verification) => verification.user)
    addressByLetterVerification: AddressByLetterVerification

    @OneToMany((type) => BankAccount, (bankAccount) => bankAccount.user)
    bankAccounts: BankAccount[]

    @OneToMany((type) => TransportRequestDraft, (draft) => draft.user)
    drafts: TransportRequestDraft[]

    @OneToOne((type) => OneTimePin, (oneTimePin) => oneTimePin.user)
    resetPasswordPin: OneTimePin | null

    @OneToOne((type) => TransportCompanyMember, (companyMember) => companyMember.loginCredentials, {
        nullable: true,
        onUpdate: 'CASCADE',
        onDelete: 'CASCADE'
    })
    @JoinColumn({ name: 'transportCompanyMemberId' })
    companyMember: TransportCompanyMember

    constructor(init?: Partial<BaseUser>) {
        Object.assign(this, init)
    }
}
