// These are all system keys found in the database of Gotha. Not guaranteed to be complete
export enum SystemKeys {
    myVia_notification_request_status_email_template = 'myVia_notification_request_status_email_template',
    myVia_notification_school_affiliation_email_template = 'myVia_notification_school_affiliation_email_template',
    myVia_notifications_configuration = 'myVia_notifications_configuration',
    consider_school_year_end_with_vacation = 'consider_school_year_end_with_vacation',
    disable_inserting_hafas_stops = 'disable_inserting_hafas_stops',
    myVia_school_affiliation_cron = 'myVia_school_affiliation_cron',
    myVia_school_affiliation_note_required = 'myVia_school_affiliation_note_required',
    VIAgo_logo = 'VIAgo_logo',
    via_news_category = 'via_news_category',
    myVia_checkboxes_configuration = 'myVia_checkboxes_configuration',
    myVia_FAQ = 'myVia_FAQ',
    myVia_evaluation_result_text = 'myVia_evaluation_result_text',
    myVia_address_verification_confirmation_email_template = 'myVia_address_verification_confirmation_email_template',
    myVia_address_verification_configuration = 'myVia_address_verification_configuration',
    myVia_logo = 'myVia_logo',
    Erziehungsberechtigte_Arten = 'Erziehungsberechtigte_Arten',
    myVia_bank_account_legal_text = 'myVia_bank_account_legal_text',
    oepnv_standard_zone = 'oepnv_standard_zone',
    myVia_info_boxes = 'myVia_info_boxes',
    myVia_school_affiliation_email_template = 'myVIA_school_affiliation_email_template',
    myVia_reset_password_pin_email_template = 'myVia_reset_password_pin_email_template',
    myVia_reset_password_bundid_email_template = 'myVia_reset_password_bundid_email_template',
    myVia_one_time_pin_email_template = 'myVia_one_time_pin_email_template',
    myVia_verification_email_template = 'myVia_email_verification_template',
    myVia_general_frontend_configuration = 'myVia_general_frontend_configuration',
    myVia_transport_request_type_cards_configuration = 'myVia_transport_request_type_cards_configuration',
    myVia_transport_request_page_configuration = 'myVia_transport_request_page_configuration',
    myVia_transport_request_decision_visibility = 'myVia_transport_request_decision_visibility',
    myVia_rechtstext_checkboxes = 'myVia_rechtstext_checkboxes',
    myVia_rechtstext_einverständnis = 'myVia_rechtstext_einverständnis',
    myVia_minimum_distance_check = 'myVia_minimum_distance_check',
    myVia_admin_users = 'myVia_admin_users',
    myVia_enabled_languages = 'myVia_enabled_languages',
    myVia_guardian_birthday_optional = 'myVia_guardian_birthday_optional',
    Footer = 'Footer',
    TransportRequestState = 'Anträge_Status',
    Klassenstufe = 'Klassenstufe',
    Schulart = 'Schulart',
    Schulform = 'Schulform',
    Schuljahr = 'Schuljahr',
    Schulprofil = 'Schulprofil',
    antraege_schueler_attribut = 'Anträge_Schüler_Attribut',
    antraege_schule_attribut = 'Anträge_Schule_Attribut',
    antraege_fussweg_attribut = 'Anträge_Fußweg_Attribut',
    antraege_orderDate_attribut = 'Anträge_OrderDate_Attribut',
    print_server_url = 'print-server-url',
    print_server_dpi = 'print-server-dpi',
    var_fields_schüler_erstattung = 'var_fields_schüler_erstattung',
    var_fields_schüler_anträge_antrag = 'var_fields_schüler_anträge_antrag',

    // These are not relevant for myVia!
    renew_login_min = 'renew_login_min',
    rollstuhl_schueler_attribut = 'rollstuhl_schueler_attribut',
    rollstuhl_tour_attribut = 'rollstuhl_tour_attribut',
    tour_show_times = 'tour_show_times',
    trash_bucket = 'trash_bucket',
    umlauf_editor_cluster_schueler_ab = 'umlauf_editor_cluster_schueler_ab',
    use_direct_way_time_optimization = 'use_direct_way_time_optimization',
    use_optimization_api_2 = 'use_optimization_api_2',
    bieter_portal_allow_bieter_anschrift_erfassen = 'bieter_portal_allow_bieter_anschrift_erfassen',
    bieter_portal_url_datenschutz = 'bieter_portal_url_datenschutz',
    bieter_portal_url_impressum = 'bieter_portal_url_impressum',
    bieter_portal_url_nutzungsbed = 'bieter_portal_url_nutzungsbed',
    bieter_portal_vergabestelle = 'bieter_portal_vergabestelle',
    changelog_id_1 = 'changelog_id_1',
    changelog_id_2 = 'changelog_id_2',
    changelog_id_6 = 'changelog_id_6',
    config_deny_duplicates = 'config_deny_duplicates',
    config_open_windows_automatically = 'config_open_windows_automatically',
    config_save_windows = 'config_save_windows',
    csv_excel_friendly = 'csv_excel_friendly',
    darkMode_1 = 'darkMode_1',
    darkMode_2 = 'darkMode_2',
    db_label = 'db_label',
    db_v_26_1 = 'db_v_26_1',
    default_bucket = 'default_bucket',
    default_ein_austiegszeit = 'default_ein_austiegszeit',
    default_ladezeit = 'default_ladezeit',
    default_ladezeit_rollstuhl = 'default_ladezeit_rollstuhl',
    default_routenberechnung = 'default_routenberechnung',
    default_schulwegezeit = 'default_schulwegezeit',
    default_wartezeit = 'default_wartezeit',
    doc_system_enabled = 'doc_system_enabled',
    experimental_features = 'experimental_features',
    hochstufung_in_status = 'hochstufung_in_status',
    hochstufung_stillgelegt_status = 'hochstufung_stillgelegt_status',
    hochstufung_von_status = 'hochstufung_von_status',
    last_db_update = 'last_db_update',
    löschroutine_event = 'löschroutine_event',
    löschroutine_schüler_fahrtdaten_monate = 'löschroutine_schüler_fahrtdaten_monate',
    löschroutine_schüler_fahrtdaten_zeitpunkt = 'löschroutine_schüler_fahrtdaten_zeitpunkt',
    löschroutine_schüler_stammdaten_monate = 'löschroutine_schüler_stammdaten_monate',
    löschroutine_schüler_stammdaten_zeitpunkt = 'löschroutine_schüler_stammdaten_zeitpunkt',
    löschroutine_touren_fahrtdaten_monate = 'löschroutine_touren_fahrtdaten_monate',
    löschroutine_touren_fahrtdaten_zeitpunkt = 'löschroutine_touren_fahrtdaten_zeitpunkt',
    löschroutine_touren_finanzdaten_monate = 'löschroutine_touren_finanzdaten_monate',
    löschroutine_touren_finanzdaten_zeitpunkt = 'löschroutine_touren_finanzdaten_zeitpunkt',
    mail_feature_activated = 'mail_feature_activated',
    mail_feature_sender_address = 'mail_feature_sender_address',
    mail_feature_sender_schema = 'mail_feature_sender_schema',
    mail_feature_sender_signature = 'mail_feature_sender_signature',
    map_center_lat = 'map_center_lat',
    map_center_lng = 'map_center_lng',
    mapbox_Api_Key = 'Key',
    oepnv_activated = 'oepnv_activated',
    oepnv_tarif = 'oepnv_tarif',
    optional_schueler_name_gbd = 'optional_schueler_name_gbd',
    overwrite_individual_entryExit_times = 'overwrite_individual_entryExit_times',
    overwrite_individual_entryExit_times_on_collective_stops = 'overwrite_individual_entryExit_times_on_collective_stops',
    portal_schulen_active = 'portal_schulen_active',
    portal_schulen_allow_changes_eigeneDaten = 'portal_schulen_allow_changes_eigeneDaten',
    portal_schulen_allow_changes_schueler = 'portal_schulen_allow_changes_schueler',
    portal_schulen_allow_changes_zeiten = 'portal_schulen_allow_changes_zeiten',
    portal_unternehmen_active = 'portal_unternehmen_active',
    portal_unternehmen_allow_changes_eigeneDaten = 'portal_unternehmen_allow_changes_eigeneDaten',
    portal_unternehmen_allow_changes_routen = 'portal_unternehmen_allow_changes_routen',
    portal_unternehmen_allow_changes_schueler = 'portal_unternehmen_allow_changes_schueler',
    portal_unternehmen_allow_changes_touren = 'portal_unternehmen_allow_changes_touren',
    portal_unternehmen_allow_changes_vertraege = 'portal_unternehmen_allow_changes_vertraege',
    portale_showFields_antraege = 'portale_showFields_antraege',
    portale_showFields_schueler = 'portale_showFields_schueler',
    portale_showFields_schulen = 'portale_showFields_schulen',
    portale_showFields_touren = 'portale_showFields_touren',
    portale_showFields_unternehmen = 'portale_showFields_unternehmen',
    prefere_radius_search = 'prefere_radius_search',
    add_multiple_people_t = 'add_multiple_people_t',
    antraege_load_newest_first = 'antraege_load_newest_first',
    antraege_varField_Klasse = 'antraege_varField_Klasse',
    antraege_varField_Schule = 'antraege_varField_Schule',
    anträge_aktiv = 'anträge_aktiv',
    Schüler_Attribut = 'Schüler_Attribut',
    ServerFormat = 'ServerFormat',
    Ticket_Nummern = 'Nummern',
    Tour_Attribut = 'Tour_Attribut',
    Vergabe_Art = 'Vergabe_Art',
    Vergabe_Gründe_Ablehnung = 'Vergabe_Gründe_Ablehnung',
    Vergabe_Gründe_Ergbnis_negativ = 'Vergabe_Gründe_Ergbnis_negativ',
    Vergabe_Gründe_Ergbnis_positiv = 'Vergabe_Gründe_Ergbnis_positiv',
    Vergabe_Gründe_Nichtzulassung = 'Vergabe_Gründe_Nichtzulassung',
    Vergabe_Gründe_Zuschlag = 'Vergabe_Gründe_Zuschlag',
    activate_schueler_extended_varfields = 'activate_schueler_extended_varfields',
    activate_schueler_pruefung = 'activate_schueler_pruefung',
    activate_touren_extended_varfields = 'activate_touren_extended_varfields',
    activate_user_accepted_legal = 'activate_user_accepted_legal',
    activate_via_overhaul = 'activate_via_overhaul',
    active_new_sidebar_in_fahrten2_panel = 'active_new_sidebar_in_fahrten2_panel',
    Beschwerde_Art = 'Beschwerde_Art',
    Landkreis = 'Landkreis',
    Logo_Pfad = 'Pfad',
    Mapbox_default_style = 'style',
    RootPath = 'RootPath',
    DB_Version = 'DB_Version',
    Fahrzeug_Art = 'Fahrzeug_Art',
    FileName = 'FileName',
    GH_Api_Key = 'Key',
    HERE_Api_Key = 'Key',
    Hochstufung = 'Hochstufung',
    Kosten_Art = 'Kosten_Art',
    Kosten_Stelle = 'Kosten_Stelle',
    custom_table_search = 'custom_table_search',
    custom_table_search_begleitpersonen = 'custom_table_search_begleitpersonen',
    custom_table_search_fahrer = 'custom_table_search_fahrer',
    custom_table_search_fahrzeuge = 'custom_table_search_fahrzeuge',
    custom_var_fields_abo_schueler = 'schueler',
    custom_var_fields_ort_schulen = 'custom_var_fields_ort_schulen',
    custom_var_fields_ska_verwaltung = 'custom_var_fields_ska_verwaltung',
    custom_var_fields_tarifstufen_verwaltung = 'custom_var_fields_tarifstufen_verwaltung',
    custom_var_fields_tarifstufen_verwaltung_einzeln = 'custom_var_fields_tarifstufen_verwaltung_einzeln',
    custom_var_fields_tarifverwaltung = 'custom_var_fields_tarifverwaltung',
    custom_var_fields_tarifverwaltung_einzeln = 'custom_var_fields_tarifverwaltung_einzeln',
    var_fields_begleitpersonen = 'var_fields_begleitpersonen',
    var_fields_fahrer = 'var_fields_fahrer',
    var_fields_fahrtanforderungen = 'var_fields_fahrtanforderungen',
    var_fields_kfz = 'var_fields_kfz',
    var_fields_schulen = 'var_fields_schulen',
    var_fields_schüler = 'var_fields_schüler',
    var_fields_schüler_anträge_entscheid = 'var_fields_schüler_anträge_entscheid',
    var_fields_touren = 'var_fields_touren',
    var_fields_umlaeufe = 'var_fields_umlaeufe',
    var_fields_unternehmen = 'var_fields_unternehmen',
    var_fields_unternehmen_betriebshoefe = 'var_fields_unternehmen_betriebshoefe',
    vergabe_external_show_times = 'vergabe_external_show_times'
}
