export enum NotificationTopic {
    REQUEST_STATUS_CHANGE = 'request_status_change',
    SCHOOL_AFFILIATION_STATUS_CHANGE = 'school_affiliation_status_change'
}

export type NotificationSettings = {
    [key in NotificationTopic]?: {
        enabled: boolean
        notifyOnApproval?: boolean
        notifyOnRejection?: boolean
    }
}
